import "./baotri.css";
import img from "./baotri.png";

const Baotri = () => {
  return (
    <div className="app baotri">
      <div className="container">
        <div className="content">
          <div className="title">HỆ THỐNG PHÁT CODE XIN TẠM DỪNG!!!</div>
          <div className="sub-title">Nâng cấp và bảo trì hệ thống</div>
          <div className="sub-content">
            <div>Chúng tôi sẽ quay trở lại sau ít phút nữa.</div>
            <div>Cảm ơn bạn đã lựa chọn đồng hành cùng chúng tôi.</div>
          </div>
        </div>
        <div className="img">
          <img className="img-baotri" src={img} alt="Bảo trì"></img>
        </div>
      </div>
    </div>
  );
};
export default Baotri;
