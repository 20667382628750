import React, { useEffect, useState } from "react";
import PromoPage from "./pages/PromoPage";
import Baotri from "./components/baotri/baotri";
import { get_data_site } from "./services/api.service";

function App() {
  const [status, setStatus] = useState(true);
  const [dataSite, setDataSite] = useState(null);

  useEffect(() => {
    setTimeout(async () => {
      let data_setting = await get_data_site();
      if (data_setting?.status_code === 200) {
        setDataSite(data_setting.result);
        setStatus(data_setting.result?.status);
        let trackingId = data_setting.result?.google_analytics;
        if(trackingId){
          const gtagScript = document.createElement('script');
          gtagScript.async = true;
          gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
          document.head.appendChild(gtagScript);
          const inlineScript = document.createElement('script');
          inlineScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${trackingId}');
          `;
          document.head.appendChild(inlineScript);
        }
      }
    }, 100);
  }, []);

  return (
    <div className="App">
      {status && <PromoPage />}
      {!status && <Baotri />}
    </div>
  );
}

export default App;
